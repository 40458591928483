<template>
    <div class="Coupon">
          <div class="item-header _flex">
            <div class="screen-list-item">
                <span class="screen-item-label">Vip订单类型:</span>
                <a-radio-group :value="VipTypeValue" @change="handleVipTypeChange" button-style="solid">
                  <a-radio-button value="1">Vip领取</a-radio-button>
                  <a-radio-button value="2">Vip购买</a-radio-button>
                  <a-radio-button value="3">人工开通</a-radio-button>
                </a-radio-group>
            </div>

            <div class="screen-list-item">
                <span class="screen-item-label">领取时间</span>
                <a-range-picker format="YYYY-MM-DD" style="width: 350px;" :placeholder="['开始时间','结束时间']" allowClear @change="changeTime"/>
            </div>

            <div class="screen-list-item">
                <a-select :value="serachContentType" style="width: 100px" @change="_changeSerachContentType">
                  <a-select-option value="username">用户名</a-select-option>
                  <a-select-option value="phone">手机号</a-select-option>
                  <a-select-option value="content">购买内容</a-select-option>
                </a-select>
                <a-input placeholder="请输入搜索内容" style="width: 200px;" @change="_changeSerachContent" allowClear/>
            </div>

            <div v-if="VipTypeValue === '2'" class="screen-list-item">
                <span class="screen-item-label">订单状态:</span>
                <a-select :value="serachParams.state" style="width: 100px" @change="_changePayState">
                  <a-select-option value="null">全部</a-select-option>
                  <a-select-option value="0">未支付</a-select-option>
                  <a-select-option value="3">已完成</a-select-option>
                  <a-select-option value="6">已退款</a-select-option>
                  <a-select-option value="7">已取消</a-select-option>
                </a-select>
            </div>
            
            <div class="screen-list-item">
                <!-- <a-button style="margin-right:20px" @click="resetBtn()">重置</a-button> -->
                <a-button type="primary" @click="serachBtn()">查询</a-button>
            </div>

            <div>
                <a-button type="primary" @click="onTtableClick" ><a-icon type="plus" />新建</a-button>
            </div>
        </div>

        
        <div class="coupon-content" :style="{'min-height':minHeight}">
            <div style="margin-top:15px;">
                <!--Vip领取-->
                <a-table v-if="VipTypeValue == 1" :loading="tableLoadState" :columns="free_columns" :dataSource="infoData" :pagination="false" bordered>
                </a-table> 

                <!--Vip购买-->
                <a-table v-if="VipTypeValue == 2" :loading="tableLoadState" :columns="vip_columns" :dataSource="infoData" :pagination="false" bordered>
                    <div slot="action" slot-scope="text, record, index">
                        <div class="edit-item" v-if="record._OrderState == 1 || record._OrderState == 3">
                            <!-- <span @click="">退款</span> -->
                            --
                        </div>
                        <div class="edit-item" v-if="record._OrderState == 6">
                            <!-- <span @click="">退款详情</span> -->
                            --
                        </div>
                    </div>
                </a-table>

                <!--人工开通-->
                <a-table v-if="VipTypeValue == 3" :loading="tableLoadState" :columns="artificial_columns" :dataSource="infoData" :pagination="false" bordered>
                    <div slot="action" slot-scope="text, record, index">
                        <div class="edit-item">
                            <span @click="_showEdit(record)">编辑</span> | 
                            <span @click="_del_vip(record)">删除</span>
                        </div>
                    </div>
                </a-table> 
            </div>
            
            <div style="margin: 20px 0;text-align: right;" v-if="total > 0">
                <a-pagination :defaultCurrent="1" :total="total" @change="pageChange" />
            </div>
        </div>


        <a-drawer title="新建" placement="right" :closable="false" width="600" @close="_hideCancel" :visible="addVisible">
             <div class="item-list">
                 <label>手机号:</label>
                 <div class="item-content">
                     <a-input v-model="addParams.phone" placeholder="请输入手机号" :disabled="is_edit"/>
                 </div>
             </div>
             <div class="item-list">
                 <label>开通内容:</label>
                 <div class="item-content">
                     <a-select :value="select_vip_manage_id" style="width: 200px" @change="_changeOpenConetnt">
                        <a-select-option v-for="(item,index) in vipManageList" :value="item.m_unique">{{item.title}}</a-select-option>
                    </a-select>
                 </div>
             </div>
             <div class="item-list">
                 <label>使用时限:</label>
                 <div class="item-content">
                    <a-input v-model="addParams.availableDays" placeholder="自动生成" disabled/>
                 </div>
             </div>
             <div class="item-list">
                 <label>到期时间:</label>
                 <div class="item-content">
                    <a-date-picker v-model="addParams.expireTime" disabled showTime/>
                 </div>
             </div>
             <div class="btn-list" style="margin-top: 100px;">
                    <a-button style="margin-right: 20px;" @click="_hideCancel">取消</a-button>
                    <a-button type="primary" @click="do_save">保存</a-button>
             </div>
        </a-drawer>

    </div>
</template>
<script>
import moment from 'moment';
export default {
    name:'VipOrder',
    components: { },
    data(){
        return{
            free_columns: [
                { title: '序号', dataIndex: 'number', key: 'number',align:'center',width:50 },
                { title: '领取内容', dataIndex: 'vipManageTitle', key: 'vipManageTitle',align:'center',width:150 },
                { title: '用户手机号', dataIndex: 'phone', key: 'phone',align:'center',width:120, },
                { title: '订单类型', dataIndex: 'OrderType', key: 'OrderType',align:'center',width:120 },
                { title: '领取时间', dataIndex: 'createTime', key: 'createTime',align:'center',width:130 },
                { title: '使用时限', dataIndex: 'availableDays', key: 'availableDays',align:'center',width:100},
                { title: '到期时间', dataIndex: 'expireTime', key: 'expireTime',align:'center',width:100 },
                { title: '用户名', dataIndex: 'username', key: 'username',align:'center',width: 120 },
                { title: '是否七田真会员', dataIndex: 'is_vip', key: 'is_vip',align:'center',width: 100, },
                { title: '订单状态', dataIndex: 'OrderState', key: 'OrderState',align:'center',width: 120 },
            ],

            vip_columns: [
                { title: '序号', dataIndex: 'number', key: 'number',align:'center',width:50 },
                { title: '订单id', dataIndex: 'orderId', key: 'orderId',align:'center',width:150 },
                { title: '购买内容', dataIndex: 'vipManageTitle', key: 'vipManageTitle',align:'center',width:100 },
                { title: '用户手机号', dataIndex: 'phone', key: 'phone',align:'center',width:120, },
                { title: '订单类型', dataIndex: 'OrderType', key: 'OrderType',align:'center',width:120 },
                { title: '支付时间', dataIndex: 'createTime', key: 'createTime',align:'center',width:130 },
                { title: '使用时限', dataIndex: 'availableDays', key: 'availableDays',align:'center',width:100},
                { title: '到期时间', dataIndex: 'expireTime', key: 'expireTime',align:'center',width:100 },
                { title: '支付金额（元）', dataIndex: 'cost', key: 'cost',align:'center',width: 120 },
                { title: '用户名', dataIndex: 'username', key: 'username',align:'center',width: 120 },
                { title: '支付渠道', dataIndex: 'payWay', key: 'payWay',align:'center',width: 100 },
                { title: '是否七田真会员', dataIndex: 'is_vip', key: 'is_vip',align:'center',width: 100, },
                { title: '订单状态', dataIndex: 'OrderState', key: 'OrderState',align:'center',width: 120 },
                { title: '操作', dataIndex: 'x', key: 'x',align:'center',width: 120 ,scopedSlots: { customRender: 'action' }},
            ],

            artificial_columns: [
                { title: '序号', dataIndex: 'number', key: 'number',align:'center',width:50 },
                { title: '开通内容', dataIndex: 'vipManageTitle', key: 'vipManageTitle',align:'center',width:150 },
                { title: '用户手机号', dataIndex: 'phone', key: 'phone',align:'center',width:120, },
                { title: '订单类型', dataIndex: 'OrderType', key: 'OrderType',align:'center',width:120 },
                { title: '开通时间', dataIndex: 'createTime', key: 'createTime',align:'center',width:130 },
                { title: '使用时限', dataIndex: 'availableDays', key: 'availableDays',align:'center',width:100},
                { title: '到期时间', dataIndex: 'expireTime', key: 'expireTime',align:'center',width:100 },
                { title: '用户名', dataIndex: 'username', key: 'username',align:'center',width: 120 },
                { title: '是否七田真会员', dataIndex: 'is_vip', key: 'is_vip',align:'center',width: 100, },
                { title: '订单状态', dataIndex: 'OrderState', key: 'OrderState',align:'center',width: 120 },
                { title: '操作', dataIndex: 'x', key: 'x',align:'center',width: 120 ,scopedSlots: { customRender: 'action' }},
            ],

            infoData:[],
            startDate: '',
            endDate: '',
            code_name:'',
            minHeight:'500px',
            total:0,
            page:1,

            serachParams:{
                content: "",
                endTime: null,
                pageNum: 1,
                pageSize: 10,
                phone: "",
                startTime: null,
                username: "",
                state:'null',
            },

            addParams:{
                m_unique:'',
                phone: "",
                availableDays:null,
                vipManageInfo:'',
                expireTime:null,
                _old_availableDays:null,
                _old_expireTime:null,
            },

            VipTypeValue:'1',
            addVisible:false,
            serachContentType:'username',
            payState:'null',
            tableLoadState:false,
            vipManageList:[],

            is_edit:false,
            select_vip_manage_id:'',
        }
    },
    created(){
        this._getData();
    },
    mounted(){
         
    },
    methods:{
     moment,
     serachBtn(){
        this.serachParams.pageNum = 1;
        this._getData();
     },
     onTtableClick(){
        this.addVisible = true;
        this._get_vip_manage();
     },
     resetBtn(){
        this.serachParams = {
            content: "",
            endTime: null,
            pageNum: 1,
            pageSize: 10,
            phone: "",
            startTime: null,
            username: "",
            state:null,
        }
        this._getData();
     },
     changeTime(value){
        if (value.length > 0) {
            this.serachParams.endTime = moment(value[0]._d).format('YYYY-MM-DD');
            this.serachParams.startTime = moment(value[1]._d).format('YYYY-MM-DD');
        } else {
            this.serachParams.endTime = '';
            this.serachParams.startTime = '';
        }
     },
     pageChange(pageNumber){
        this.serachParams.pageNum = pageNumber;
        this._getData();
     },
     _getData(){
        let urlCode;
        this.tableLoadState = true;
        // this.$message.loading('数据加载中...');
        if (this.VipTypeValue == 1 || this.VipTypeValue == 3) {
            urlCode = 8000165;
            this.serachParams.vip_type = this.VipTypeValue;
        } else {
            urlCode = 8000166;
        }
        this.$axios.post(urlCode, this.serachParams,res => {
            if(res.data.code==1){
                console.log(res.data);
                this.infoData = res.data.data.list;
                this.total = res.data.data.count;
            }else{
                this.infoData = [];
                this.total = 0;
            }
            this.tableLoadState = false;
            // this.$message.destroy()
        });
     },
     _showEdit(item){
        console.log(item);
        
        this.addParams.m_unique = item.m_unique;
        this._get_vip_manage();
        this.$axios.post(8000169, {m_unique:item.m_unique},res => {
            if(res.data.code==1){
                let data = res.data.data;
                this.is_edit = true;
                this.addVisible = true;
                this.addParams.phone = data.phone;
                this.addParams.availableDays = data.available_days;;
                this.addParams.availableDays = data.available_days;;
                this.addParams.expireTime = moment(data.expire_time);
                this.addParams.vipManageInfo = this._vip_manage(data.vip_manage_id);
                this.select_vip_manage_id = data.vip_manage_id;
                this.addParams._old_availableDays = data.available_days;
                this.addParams._old_expireTime = data.expire_time;
            } else {
                this.$message.error('找不到该数据~');
            }
        });
     },
     _hideCancel(){
        this.is_edit = false;
        this.addVisible = false;
        this.select_vip_manage_id = '';
        this.addParams = {
            m_unique:'',
            phone: "",
            availableDays:null,
            vipManageInfo:'',
            expireTime:null,
        }
     },
     _changeVisible(){
        this.addVisible = true;
     },
     do_save(){
        let params = this.addParams;
        params.expireTime = moment(params.expireTime).format('YYYY-MM-DD HH:mm:ss');
        params.vipManageInfo = JSON.stringify(params.vipManageInfo);
        this.$axios.post(8000168, params,res => {
            if(res.data.code==1){
                this.serachParams.pageNum = 1;
                this._hideCancel();
                this._getData();
            }else{
                this.$message.error(res.data.message);
            }
            this.$message.destroy()
        });
     },
     _set_course_value(courseId,courseTitle){
        this.courseId = courseId;
        this.courseTitle = courseTitle;
     },
     onChangeUseTime(value){
        if (value.length > 0) {
            this.startTime = moment(value[0]._d).format('YYYY-MM-DD');
            this.expireTime = moment(value[1]._d).format('YYYY-MM-DD');
        } else {
            this.startTime = '';
            this.expireTime = '';
        }
     },
     handleVipTypeChange(event){
		 this.tableLoadState = false;
        this.VipTypeValue = event.target.value;
        this.serachParams.pageNum = 1;
        this._getData();
     },
     _changeSerachContentType(value){
        this.serachContentType = value;
     },
     _changeSerachContent(event){
        let value = event.target.value;
		this.serachParams.username = '';
		this.serachParams.phone = '';
		this.serachParams.content = '';
        switch (this.serachContentType) {
            case 'username':
                this.serachParams.username = value;
                break;
            case 'phone':
                this.serachParams.phone = value;
                break;
            case 'content':
                this.serachParams.content = value;
                break;
        }
     },
     _changePayState(value){
        console.log(value);
        this.serachParams.state = value;
     },
     _get_vip_manage(){
        this.$axios.post(8000167, {},res => {
            if(res.data.code==1){ this.vipManageList = res.data.data; }
        });
     },
     _changeOpenConetnt(value){
        this.select_vip_manage_id = value;
        this.addParams.vipManageInfo = this._vip_manage(value);
        this.addParams.availableDays = this.addParams.vipManageInfo.available_days;
        this.addParams.expireTime = moment().add(this.addParams.availableDays, 'days');
     },
     _changeExpireTime(value){
        if (value) {
            this.addParams.expireTime = value._d;
        }
     },
     _vip_manage(id){
        if (this.vipManageList.length == 0) {return '';}
        let data = '';
        for (var i = 0; i < this.vipManageList.length; i++) {
            if (this.vipManageList[i].m_unique == id) {
                data = this.vipManageList[i];
                break;
            }
        }
        return data;
     },
     _del_vip(item){
        let that = this;
        this.$confirm({
            title: '温馨提示',
            content: '确认要删除VIP权限吗？',
            onOk() {
                that.$axios.post(8000170, {m_unique:item.m_unique},res => {
                if(res.data.code==1){
                    that.serachParams.pageNum = 1;
                    that._getData();
                }else{
                    that.$message.error(res.data.message);
                }
            });
            },
            onCancel() {
                console.log('no');
            },
        });
     },
    },
}
</script>

<style lang="less" > 
    .Coupon{
         .item-header{
            padding: 15px 20px;
            background: #fff;
            border-radius: 4px;
            .screen-list-item{
                margin-right: 20px;
            }
            .screen-item-label{
                margin-right: 10px;
                color: #666;
                font-size: 14px;
                white-space: nowrap;
            }
        }
        .coupon-content{
            padding: 15px;
            background: #fff;
            margin-top: 20px;
            border-radius: 4px;
            .content-title{
                span{
                    font-size: 18px;
                    color: #333;
                }
                i{
                    margin-left: 10px;
                    font-size: 18px;
                    cursor: pointer;
                }
            }

            .smalldash{
                line-height: 30px;
            }
            .edit-item{
                font-size:12px;
            }
        }
    }
    .Coupon .ant-select-selection--single,.Coupon .ant-input{height: 36px;}
    .Coupon .ant-select-selection__rendered{line-height: 34px;}
    .Coupon .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 16px 6px;
    }
    .coupon_form{padding-bottom: 30px;}
    .coupon_form .ant-row .ant-form-item-label{text-align: left;}
    .coupon_form .ant-radio-group .ant-input-number{width: 124px;}
    .coupon_form .ant-input-number{width:330px;}

    .item-list{
        display: flex;
        overflow: hidden;
        margin-bottom: 25px;
        .item-content{
            float: right;
            width: 400px;
        }
        label{
            margin-top: 6px;
            margin-right: 15px;
        }
    }

    .edit-item > span {
        color: #2473d5;
        cursor: pointer;
    }

    ._flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        /* justify-content: space-between; */
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
</style>